export enum FusionPromotionStates {
  CREATING_PR = 'Creating Pull Request',
  PENDING_REVIEW = 'Pending Review',
  APPROVED = 'Approved',
  MERGED = 'Merged',
  WITHDRAWN = 'Withdrawn',
  REJECTED = 'Rejected',
  PR_CREATING_ERROR = 'Pull Request Creation Error',
  BROKEN = 'Broken',
  PR_CHECKING_ERROR = 'Pull Request Checking Error',
}
export enum FusionPromotionFileTypes {
  FILE = 'FILE',
  DIRECTORY = 'DIR'
}
export enum FusionPromotionFileTreeItemActions {
  SELECT = 'selectForPromotion',
  REMOVE = 'removeFromPromotion',
}
export interface FusionPromotionFile {
  path: string;
  name: string;
  size: number;
  downloadUrl: string;
  type: string;
  restricted: boolean;
}
export interface FusionPromotionFileTree extends FusionPromotionFile {
  children: FusionPromotionFileTree[];
}
export interface FusionPromotionIntegratedSystem {
  id: string;
  name: string;
  type: string;
}
export interface TransformationConfiguration {
  pathSelectionRegEx: string;
  target: string;
  replacement: string;
}
export interface FusionPromotion {
  state: string;
  prUrl: string;
  selectedFiles: FusionPromotionFile[];
  fileTransformationConfigurations?: TransformationConfiguration[];
  contentTransformationConfigurations?: TransformationConfiguration[];
  createdBy: string;
  creationDate: string;
  lastModified?: string;
  approvedBy?: string;
  approvedDate?: string;
}

export interface FusionPromotionRequest extends FusionPromotion {
  sourceIntegratedSystemId: string;
  targetIntegratedSystemId: string;
}
export interface FusionPromotionResponse extends FusionPromotion {
  id: string;
  sourceIntegratedSystem: FusionPromotionIntegratedSystem;
  targetIntegratedSystem: FusionPromotionIntegratedSystem;
}
export interface ConfigurationPromotionErrorMessage {
  id: string;
  message: string;
  count: number;
  files: { fileId: string, additionalMessage?: string }[];
}
export interface ConfigurationPromotionError {
  id: string;
  totalFailedTests: number;
  totalFailedFiles: number;
  totalAutoApprovedFiles: number;
  entirePromotionAutoApproved: boolean;
  messages: ConfigurationPromotionErrorMessage[];
  creationDate: string;
}